/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {  makeStyles } from '@material-ui/core/styles';
import {
    arrayOf, bool, string, shape,
} from 'prop-types';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';
import mbpLogger from 'mbp-logger';
import customBreakpoints from '../../../helpers/customBreakpoints';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getAnalyticsStorageData } from '../../../helpers/tracking/session/sessionTrackingHelpers';

const useStyles = makeStyles((theme) => ({
    container1: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '53px',
        backgroundColor: theme.palette.desktopHeaderMenu.background,
        borderBottom: theme.palette.desktopHeaderMenu.border,
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            display: 'none',
        },
    },
    container2: {
        width: theme.palette.desktopHeaderMenu.maxWidth,
        display: 'inline-block',
    },

    wrapper: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
        boxSizing: 'border-box',
        height: '53px',
        margin: 0,
    },

    brandTabsStyle: {},

    brandTabStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        height: 'inherit',
        width: '100%',
        borderBottom: 0,
        '&:not(:last-child)': {
            borderRight: '1px solid #F5F5F5',
        },
        '& div': {
            display: 'inline-block',
            textAlign: 'center',
            width: '100%',
        },
        '&:hover::after': {
            content: "''",
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
            paddingBottom: '1px',
            borderBottom: '2px solid',
            borderBottomColor: 'inherit',
        },
    },

    brandTabImageStyle: {
        width: '100%',
        maxWidth: '86px',
        height: '32px',
        verticalAlign: 'middle',
    },
}));

const BrandTabs = ({ menu, currentBrand, ffIsAttributionBrandTabsSupportEnabled }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const  getBrandHost = (brand) => {
        const currentHost = window?.location?.host;
        const domainBrandTab = brand?.domain;
        const brandHost = currentHost.split('.');

        if (brandHost?.length === 3) {
            brandHost[1] = domainBrandTab;
        } else if (brandHost?.length === 1) {
            // if doesn't split is localhost:port
            brandHost[0] = `pwa.${domainBrandTab}.${currentHost}`;
        }

        return brandHost.join('.');
    };

    const handleClick = (e, brand) => {
        dispatch(trackEvent({
            eventCategory: 'Cross Brand Nav', eventAction: 'Click', eventLabel: brand?.fullBrandName,
        }));

        if (typeof window !== 'undefined') {
            e.preventDefault();
            e.stopPropagation();

            const cookies = new Cookies();
            const analyticsSession = getAnalyticsStorageData();
            const entryBrandCookie = cookies.get('entryBrand') || currentBrand?.domain || '';
            const bannerCode = cookies.get('banner')?.c || '';

            // Set the entryBrand that the domain have currently
            const reqObj = [];

            // Attribution:Full URL passthrough
            if (ffIsAttributionBrandTabsSupportEnabled) {
                // Append full attribution to URL for both backend systems (r) and Tealium (utms/gclid)
                if (analyticsSession
                    && (analyticsSession.landingParams && Object.keys(analyticsSession.landingParams).length > 0)
                ) {
                    const attributionParamsQueryString = new URLSearchParams(analyticsSession.landingParams).toString();

                    reqObj.push(attributionParamsQueryString);
                }
            } else { // Default explicit param handling (bannerCode and DID)
                if (analyticsSession
                    && (analyticsSession.landingParams && Object.keys(analyticsSession.landingParams).length > 0)
                ) {
                    // DID value (PMall attribution support)
                    if (analyticsSession.landingParams.did) {
                        reqObj.push(`did=${analyticsSession.landingParams.did}`);
                    }
                }

                if (bannerCode) {
                    reqObj.push(`r=${bannerCode}`);
                }

                if (entryBrandCookie) {
                    reqObj.push(`entryBrand=${entryBrandCookie}`);
                }

                if (bannerCode
                    && !reqObj.includes(`r=${bannerCode}`) // checking if r param not exist from line 131
                ) {
                    reqObj.push(`r=${bannerCode}`);
                }
            }

            const brandHost = getBrandHost(brand);

            window.location.href = `${window.location.protocol}//${brandHost}/?${reqObj.join('&')}`;
        } else {
            window.location.href = brand?.link;
        }
    };

    // menu is array of objects with properties:
    //   label - brand code (e.g. HD)
    //   link - URL that brand image will link to
    //   icon - URL of brand's image file
    //   fullBrandName - full name of brand e.g. '1800flowers'
    //   domain - e.g. '18F'
    //   storeId - number
    //   catalogId - number
    const omitBrandCode = currentBrand?.code ? currentBrand.code : '';

    const docReferrer = (typeof document !== 'undefined') ? document.referrer : null;

    if (docReferrer) {
        let brandCheck;
        menu.filter((brand) => {
            const brandDomain = brand.link?.split('.')?.[1];
            const referrerDomain = docReferrer.split('.')?.[1];
            if (brandDomain === referrerDomain) {
                brandCheck = brand.link;
            }
            return brandCheck;
        });
        if (brandCheck) {
            const cookies = new Cookies();
            cookies.set('REFERRER', brandCheck, {
                path: '/',
                encode: String,
            });
        }
    }
    return (
        <div className={classes.container1}>
            <div className={classes.container2}>
                <div className={classes.wrapper} data-testid="brand-tabs">
                    {
                        menu?.map((brand) => {
                            if (!brand?.label) {
                                mbpLogger.logError({
                                    appName: process.env.npm_package_name,
                                    message: `BrandTabs: bad input or invalid brand ${brand?.label}`,
                                    brand,
                                });
                                return null;
                            }

                            if (brand?.label === omitBrandCode) {
                                return null;
                            }

                            const brandCfg = brand?.label;
                            const bdrBottomBColor = brandCfg.palette && brandCfg.palette.colorPrimary ? brandCfg.palette.colorPrimary : 'black';

                            return (
                                <a
                                    href={brand?.link}
                                    onClick={(e) => handleClick(e, brand)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    id={brand?.label}
                                    key={brand?.label}
                                    className={classes.brandTabStyle}
                                    style={{ borderBottomColor: bdrBottomBColor }}
                                >
                                    <div key={brand?.label}>
                                        <img
                                            src={`${brand?.icon}?height=32&width=78`}
                                            alt={`${brand?.fullBrandName} Homepage`}
                                            className={classes.brandTabImageStyle}
                                        />
                                    </div>
                                </a>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

BrandTabs.propTypes = {
    menu: arrayOf(shape({
        brand: shape({
            label: string.isRequired,
            icon: string.isRequired,
            fullBrandName: string.isRequired,
        }),
    })).isRequired,
    currentBrand: shape({
        domain: string.isRequired,
        code: string.isRequired,
    }).isRequired,
    ffIsAttributionBrandTabsSupportEnabled: bool.isRequired,
};

export default BrandTabs;
