/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { object, bool, string } from 'prop-types';
import { connect, useSelector } from 'react-redux';
import memberDucks from '../../../../../../state/ducks/Member/ducks';
import { getFeatureFlags, getPassportPageUrl, getPresentationFamily } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import iconList from '../../../../../pages/Account/assets/svg/LeftMenuIcons';

const {
    common: {
        commonSelectors,
    },
} = memberDucks;
const SignedInDropdown = ({
    classes, featureFlags, isAuthenticated, passportPageUrl,
}) => {
    const isFoodBrandIcon = useSelector(getPresentationFamily) === 'food' && featureFlags['is_mobile_menu_account_icons'];
    const isSignInDropdownHidingAccountOverview = featureFlags['is-signin-dropdown-hiding-account-overview'];

    return (
        isFoodBrandIcon
            ? (
                <div className={`${classes.actionsWrapperIcon} ${classes.actionsWrapper}`}>
                    {!isSignInDropdownHidingAccountOverview && <Link className={classes.action} to="/account">{iconList('#000000').account}<span>Your Account</span></Link>}
                    {isAuthenticated && featureFlags['is-address-book-service-enabled']
                        && <Link className={classes.action} to="/account/address-book">{iconList('#000000').addressAccountBook}<span>Address Book </span></Link>}
                    <Link className={classes.action} to={passportPageUrl}>{iconList('#000000').celebration}<span>Celebrations Passport</span></Link>
                    <a className={classes.action} href="/account/orders">{iconList('#000000').truck}Track Your Orders</a>
                    <hr />
                    <Link to="/auth/logout" data-test="auth-logout" id="auth-logout-link" name="authLogoutLink" className={classes.action}>{iconList('#000000').logout}<span>Sign Out</span></Link>
                </div>
            ) : (
                <div className={classes.actionsWrapper}>
                    {!isSignInDropdownHidingAccountOverview && <Link className={classes.action} to="/account">Your Account</Link>}
                    {isAuthenticated && featureFlags['is-address-book-service-enabled']
                        && <Link className={classes.action} to="/account/address-book">Address Book</Link>}
                    <Link className={classes.action} to={passportPageUrl}>Celebrations Passport</Link>
                    <a className={classes.action} href="/account/orders">Track Your Orders</a>
                    <hr />
                    <Link to="/auth/logout" data-test="auth-logout" id="auth-logout-link" name="authLogoutLink" className={classes.action}>Sign Out</Link>
                </div>
            )
    );
};

SignedInDropdown.propTypes = {
    classes: object.isRequired,
    featureFlags: object.isRequired,
    isAuthenticated: bool.isRequired,
    passportPageUrl: string.isRequired,
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    isAuthenticated: commonSelectors.getIsAuthenticatedStatus(state),
    passportPageUrl: getPassportPageUrl(state),
});

export default connect(mapStateToProps)(SignedInDropdown);
