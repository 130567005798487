/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import {
    object, string, func, shape, number, bool,
} from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Cookies  from 'universal-cookie';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getPresentationFamily } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { actions as tagManagerActions } from '../../../../state/ducks/TagManager';

import { commonSelectors } from '../../../../state/ducks/Common';
import { cartStatusCompleteList } from '../../../helpers/common/helper';
import useIsKeeper from '../../../helpers/hooks/useIsKeeper';

const styles = (theme) => ({
    cartEmpty: {
        color: '#969696',
    },
    cartFill: {
        color: '#b30200',
    },
    cartTextStyle: {
        display: 'block',
    },
    flowersCartIcon: {},
    flowerCartMobile: {
        padding: '0 !important',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        textAlign: 'center',
        fontWeight: 'bold',
        letterSpacing: '0.6px',
    },
    cartMobile: {
        // CSS Grid
        gridRow: '1',
        gridColumn: '5',
        fontSize: '10px',
        textTransform: 'uppercase',
        letterSpacing: '.2px',
        borderRadius: '50%',
        color: theme.palette.cms?.mobileMenuTextColor || theme.palette.mobileMenuColor,
        cursor: 'pointer',
        textDecoration: 'none',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'normal',
        '& img': {
            width: '24px',
            height: '24px',
        },
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
    flowersQty: {},
    qtyCommon: {
        width: '15px',
        color: theme.palette.mobileMenuColor,
        height: '15px',
        display: 'flex',
        position: 'absolute',
        boxSizing: 'content-box',
        fontWeight: '500',
        alignItems: 'center',
        borderRadius: '50%',
        pointerEvents: 'none',
    },
    qtyItem: {
        top: '1px',
        right: '8px',
        border: `1px solid ${theme.palette.mobileMenuColor}`,
        fontSize: '12px',
        justifyContent: 'center',
        backgroundColor: theme.palette.success?.main,
        '&$flowersQty': {
            top: '-3px',
            right: '5px',
        },
    },
    qtyItemFood: {
        top: '3px',
        right: '3px',
        fontSize: '15px',
    },

    gatheringFlowerCartMobile: {
        gridColumn: 6,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cartText: {
        lineHeight: '12px',
        color: theme.palette.cms?.mobileMenuTextColor || theme.palette.mobileMenuColor,
        marginTop: '3px',
        fontFamily: 'Lato',
    },
});

const setItemCountCookie = (cookieValue, domain) => {
    const cookies = new Cookies();
    cookies.set('itemCount', cookieValue, {
        path: '/',
        encode: String,
        domain: `.${domain}.com`,
    });
};

const Cart = ({
    orderId, image, classes, orderStatus,
    trackEvent, brand, type, presentationFamily,
    description, cartCount, isGatheringsPage,
}) => {
    const itemCount = !cartStatusCompleteList.includes(orderStatus) ? cartCount : 0;
    const domainName =  brand?.domain || '';
    const isKeeper = useIsKeeper();
    if (itemCount !== 0) {
        setItemCountCookie(itemCount, domainName);
    }

    if (type === 'mobile') {
        return (
            <Link
                to={`/checkout/cart/${orderId}`}
                aria-hidden="true"
                tabIndex="-1"
                className={`${classes.cartMobile} ${presentationFamily === 'flower' && classes.flowerCartMobile} ${(isGatheringsPage || isKeeper) ? classes.gatheringFlowerCartMobile : ''}`}
                onClick={() => {
                }}
                alt="icon cart mobile" // beware => icon-cart-mobile
                aria-label="Cart-icon"
            >
                <span className={classes.container}>
                    {image && <img src={image} className={`${presentationFamily === 'flower' && classes.flowersCartIcon}`} alt={description} aria-hidden="true" width="24" height="24" />}
                    <span className={classes.cartText}>
                        {description}
                    </span>
                    {itemCount > 0 && <span className={presentationFamily === 'flower' ? `${classes.qtyCommon} ${classes.qtyItem} ${classes.flowersQty}` : `${classes.qtyCommon} ${classes.qtyItemFood}`}>{itemCount}</span> }
                </span>
            </Link>
        );
    }

    return (
        <Link
            to={`/checkout/cart/${orderId}`}
            id="hp-cart"
            data-test="hp-cart-icon"
            name="cartIcon"
            aria-hidden="true"
            tabIndex="-1"
            onClick={() => {
                let origin = '';
                if (typeof window !== 'undefined' && window.location && window.location.origin) {
                    origin = window.location.origin;
                }
                trackEvent({
                    eventCategory: 'Header',
                    eventAction: 'Cart',
                    eventLabel: '<<pageType>>',
                    link_text: 'Cart',
                    header_section: 'Core Browsing Header',
                    link_url: `${origin}/checkout/cart/${orderId}`,
                });
            }}
            alt="cart-icon"
            aria-label="Cart-icon"
        >
            <img src={`${image}?height=25&width=44`} alt="Cart Icon" aria-hidden="true" role="presentation" width="24" height="24" />
            <span
                className={classes.cartTextStyle}
                style={itemCount ? { color: '#a00' } : { color: '#000' }}
            >
                ({itemCount}){' '}Cart
            </span>
            {' '}
            {/* In production, item count comes from a cookie */}
        </Link>
    );
};

Cart.propTypes = {
    cartCount: number,
    orderId: string,
    image: string.isRequired,
    classes: object.isRequired,
    orderStatus: string,
    trackEvent: func.isRequired,
    brand: shape({
        domain: string,
    }),
    type: string,
    description: string,
    presentationFamily: string,
    isGatheringsPage: bool,
};

Cart.defaultProps = {
    cartCount: 0,
    orderId: '',
    orderStatus: '',
    brand: {},
    type: 'desktop',
    description: '',
    presentationFamily: null,
    isGatheringsPage: false,
};

const mapDispatcherToProps = (dispatch) => ({
    trackEvent: bindActionCreators(tagManagerActions.trackEvent, dispatch),
});

const mapStateToProps = (state) => ({
    cartCount: commonSelectors.getTotalItemInCart(state),
    orderId: commonSelectors.getOrderId(state),
    orderStatus: commonSelectors.getOrderStatus(state),
    brand: getBrand(state),
    presentationFamily: getPresentationFamily(state),
});

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatcherToProps),
)(Cart);
