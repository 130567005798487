/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React, { useState } from 'react';
import {
    object, string, shape, bool,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// ui components
import SignInDropdown from './partials/SignInDropdown';
import SignedInDropdown from './partials/SignedInDropdown';

// redux, gql, helpers
import memberDucks from '../../../../../state/ducks/Member/ducks';
import { trackData } from '../../../../helpers/tracking/common/commonTrackingHelpers';

const {
    common: {
        commonSelectors,
    },
} = memberDucks;

const styles = (theme) => ({
    signInContainer: {
        cursor: 'pointer !important',
        position: 'relative !important',
        textAlign: 'center !important',
        fontSize: '12px !important',
        color: '#000 !important',
        backgroundColor: 'transparent !important',
        border: 0,
        paddingBottom: 0,
        paddingTop: 0,
        minWidth: 40,
        '&:hover $signInDropdown, &:focus $signInDropdown, &:active $signInDropdown': {
            visibility: 'visible !important',
            opacity: 1,
            transform: 'translate(-50%,0) !important',
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12) !important',
        },
    },
    signInDropdown: {
        visibility: 'hidden !important',
        opacity: 0,
        transition: 'opacity 332ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 221ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
        boxShadow: 'none !important',
        zIndex: '999 !important',
        width: '260px !important',
        padding: '10px !important',
        position: 'absolute !important',
        top: '100% !important',
        left: '50% !important',
        transform: 'translate(-50%,7px) !important',
        backgroundColor: '#FFF !important',
        border: '1px solid #ccc !important',
    },
    signInButton: {
        display: 'block !important',
        width: '100% !important',
        lineHeight: '30px !important',
        position: 'relative !important',
        backgroundColor: `${theme.palette.cms?.primary || theme.palette.colorPrimary} !important`,
        color: `${theme.palette.colorPrimaryContrast} !important`,
        textDecoration: 'none !important',
        fontWeight: 'bold !important',
        fontSize: '14px !important',
        textAlign: 'center !important',
        marginBottom: '10px !important',
        '&:hover': {
            backgroundColor: `${theme.palette.cms?.primary || theme.palette.colorPrimary} !important`,
        },
        '&:focus': {
            outline: 'none',
        },
    },
    createAccountContainer: {
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 'bold',
        marginBottom: '10px',
        '& p': {
            margin: 0,
            display: 'block',
        },
        '& a$clickLink': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            textDecoration: 'none',
            fontSize: '14px',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    clickLink: {
        // leave empty
    },
    facebookButton: {
        color: '#FFF !important',
        marginBottom: '8px !important',
        borderRadius: '3px !important',
        display: 'flex !important',
        alignItems: 'center !important',
        width: '100% !important',
        backgroundColor: '#3b5998 !important',
        lineHeight: '28px !important',
        textTransform: 'none !important',
        '&:focus': {
            outline: '1px solid',
        },
        '&:hover': {
            backgroundColor: '#3b5998 !important',
        },
        '& span': {
            fontSize: '13px !important',
            marginLeft: '10px !important',
            color: `${theme.palette.colorPrimaryContrast} !important`,
        },
    },
    googleButton: {
        marginBottom: '10px !important',
        borderRadius: '3px !important',
        display: 'flex !important',
        alignItems: 'center !important',
        width: '100% !important',
        backgroundColor: '#FFF !important',
        lineHeight: '28px !important',
        textTransform: 'none !important',
        border: `1px solid ${theme.palette.colorNeutral40} !important`,
        '&:focus': {
            outline: `1px solid ${theme.palette.colorNeutral30}`,
        },
        '& span': {
            textDecoration: 'none !important',
            color: `${theme.palette.colorNeutral80} !important`,
            fontSize: '13px !important',
            marginLeft: '10px !important',
        },
        '&:hover': {
            backgroundColor: `${theme.palette.colorPrimaryContrast} !important`,
        },
    },
    actionsWrapper: {
        marginBottom: '10px',
        lineHeight: '1.7',
        '& a$action': {
            textAlign: 'left',
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
    },
    action: {
        display: 'block',
        textDecoration: 'none',
        fontSize: '14px',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    benefits: {
        border: `2px solid ${theme.palette.colorNeutral60}`,
        borderRadius: '5px',
        padding: '5px 10px 5px 10px',
        lineHeight: '1.5',
        fontSize: '14px',
        textAlign: 'left',
        '& ul': {
            margin: '0',
            padding: '0 0 0 16px',
            fontSize: '13px',
            '& li': {
                margin: 0,
                textAlign: 'left',
            },
        },
    },
    actionsWrapperIcon: {
        '& a$action': {
            textAlign: 'left',
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            display: 'flex',
            alignItems: 'center',
            padding: '1.1px 0',
            '& svg': {
                marginRight: '6px',
                width: '24px',
            },
        },
    },
});

const SignIn = ({
    classes, image, getFullName, isAuthenticated,
}) => {
    const [tagImpressionState] = useState(null);
    const shortenFit = (s) => (s.length <= 6 ? s : `${s.substr(0, 6)}…`);
    trackData({
        tagImpressionState, // data provided
        action: 'track_siginin_dropdown',
    });

    return (
        <>
            <div id="signInDropdown" data-test="sign-in-dropdown" name="signinDropdown" className={classes.signInContainer}>
                <img src={image || ''} alt="Sign In Icon" aria-hidden="true" role="presentation" data-test="hp-sign-in-icon" id="hp-sign-in" name="signInIcon" height="25" width="25" />
                <div>
                    {isAuthenticated && getFullName && getFullName.firstName !== '' ? shortenFit(getFullName.firstName) : 'Sign In'}
                </div>
                <div
                    className={classes.signInDropdown}
                    id="sign-in-dropdown"
                >
                    {!isAuthenticated ? (
                        // if not signed in
                        <SignInDropdown classes={classes} />
                    ) : (
                        // if signed in
                        <SignedInDropdown classes={{ actionsWrapper: classes.actionsWrapper, action: classes.action, actionsWrapperIcon: classes.actionsWrapperIcon }} />
                    )}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    getFullName: commonSelectors.getFirstLastName(state),
    isAuthenticated: commonSelectors.getIsAuthenticatedStatus(state),
});

SignIn.propTypes = {
    classes: object.isRequired,
    image: string.isRequired,
    getFullName: shape({
        firstName: string.isRequired,
        lastName: string.isRequired,
    }).isRequired,
    isAuthenticated: bool.isRequired,
};

const enhance = compose(
    connect(mapStateToProps, null),
    withStyles(styles),
);

export default enhance(SignIn);
