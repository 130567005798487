/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// Link tokens allow us to inject brand data into links (mostly for tracking purposes)
// E.g. When this link is specified in contentstack for brandtabs:
//    https://www.personalizationmall.com/?did=xxINSERTPMALLBRANDDIDxx&gcampaignID=181818&utm_source=Sister_Brand&utm_medium=Referral&utm_campaign=xxINSERTBRANDxx&utm_content=X-Brand_Click
//
// the link will be rendered as the following for 1800flowers.com site:
//    https://www.personalizationmall.com/?did=559762&gcampaignID=181818&utm_source=Sister_Brand&utm_medium=Referral&utm_campaign=18F&utm_content=X-Brand_Click
//
// Tokens currently available are:
//   xxINSERTBRANDxx          brand code.  E.g. 18F HD 18B ...
//   xxINSERTPMALLBRANDDIDxx  The personalization mall DID for the brand.  E.g. 559762 for 18F
//

export default function replaceLinkTokens(link, brand) {
    if (!link || !brand) return link;
    let result = link;
    result = result.replace(/xxINSERTBRANDxx/ig, brand.code || '');
    result = result.replace(/xxINSERTPMALLBRANDDIDxx/ig, brand['personalization-mall-did'] || '');
    return result;
}
