/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React from 'react';
import {
    bool, array, object, string,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import makeStyles from '@material-ui/core/styles/makeStyles';

// ui components
import ResponsiveImage from '../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';

// redux, helpers
import customBreakpoints from '../../../../helpers/customBreakpoints';
import { getBrand } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { country } from '../../../../../state/ducks/App/App-Selectors';
import {  getPresentationFamily } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getCountryURL } from '../../../../helpers/InternationalCountryURL';
import useIsKeeper from '../../../../helpers/hooks/useIsKeeper';

const useStyles = makeStyles((theme) => ({
    HeaderLogoContainer: {
        display: 'flex',
        flexGrow: '.65',
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            flexGrow: '.75',
            '& img': {
                width: '25vw',
                maxWidth: '150px',
            },
        },
    },
    logo: {
        display: 'flex',
    },
    foodBrandLogo: {
        height: 52,
        width: 'auto',
        paddingTop: 4,
    },
    flowersBrandLogo: {
        height: 62,
        width: 'auto',
        paddingTop: 4,
    },
    HeaderLogoContainerPla: {
        display: 'flex',
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            flexGrow: '.75',
            '& img': {
                width: '25vw',
                maxWidth: '150px',
            },
        },
    },
    gatheringBrandLogo: {
        height: 70,
        width: 'auto',
        paddingTop: 15,
    },
    borderLeft: {
        borderRight: `1px solid ${theme.palette.colorNeutral60}`,
        margin: '18px 2px 18px 10px',
    },
    partnershipLogo: {
        height: 70,
        width: 'auto',
        paddingTop: 15,
    },
}));

const findMenuBlockObj = (menuBlocks, blockName) => {
    const results = menuBlocks.filter((menuBlock) => (Object.keys(menuBlock)[0]) === blockName);
    return results.length ? results[0][blockName] : null;
};

const ContainerBrandLogo = (props) => {
    const {
        menuBlocks, plaEnabled, keeperData, partnershipLogo,
    } = props;
    const isKeeper = useIsKeeper();
    const classes = useStyles();
    const presentation_family = useSelector(getPresentationFamily);
    const brand = useSelector(getBrand);
    const location = useLocation();
    const countryName = useSelector(country);
    const menuBlockObj = findMenuBlockObj(menuBlocks, 'logo');
    const countryURL = getCountryURL(countryName, location?.pathname);
    const countryIsCanadaOrEngland = menuBlockObj?.link?.href === '/canada' || menuBlockObj?.link?.href === '/england';
    const logoDimensions = {
        height: presentation_family === 'food' ? 52 : 62,
        width: 'auto',
    };

    if (!menuBlockObj?.image?.url) {
        return null;
    }

    return (
        <div className={plaEnabled ? classes.HeaderLogoContainerPla : classes.HeaderLogoContainer} data-testid="brand-logo">
            <Link to={countryIsCanadaOrEngland ? menuBlockObj?.link?.href : countryURL} className={classes.logo}>
                <ResponsiveImage
                    path={menuBlockObj.image.url}
                    alt={menuBlockObj.image.title || brand?.domain}
                    className={presentation_family === 'food' ? classes.foodBrandLogo : classes.flowersBrandLogo}
                    params={{
                        desktop: 'quality=100',
                        tablet: 'quality=100',
                        mobile: 'quality=100',
                    }}
                    dimensions={logoDimensions}
                />
            </Link>
            {presentation_family !== 'food' && location?.pathname === '/gatheringus' && !isKeeper && !partnershipLogo
            && (
                <>
                    <div className={classes.borderLeft} />
                    <div>
                        <ResponsiveImage
                            path="https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blte371ca3c11f15e9a/610ba12f93c9a33d7352f57b/desktopGatheringLogo.png"
                            alt="Gathering Logo"
                            className={classes.gatheringBrandLogo}
                            params={{
                                desktop: 'quality=100',
                                tablet: 'quality=100',
                                mobile: 'quality=100',
                            }}
                            dimensions={logoDimensions}
                        />
                    </div>
                </>
            )}
            {isKeeper && keeperData?.logo_desktop?.url
                && (
                    <>
                        <div className={classes.borderLeft} />
                        <div>
                            <ResponsiveImage
                                path={keeperData?.logo_desktop?.url}
                                alt="Keeper Logo"
                                className={classes.gatheringBrandLogo}
                                style={{ padding: '15px 0px 10px 20px' }}
                                params={{
                                    desktop: 'quality=100',
                                    tablet: 'quality=100',
                                    mobile: 'quality=100',
                                }}
                                dimensions={logoDimensions}
                            />
                        </div>
                    </>
                )}

            {partnershipLogo && (
                <>
                    <div className={classes.borderLeft} />
                    <div>
                        <ResponsiveImage
                            path={partnershipLogo}
                            alt="Partnership Logo"
                            className={classes.partnershipLogo}
                            style={{ padding: '15px 0px 10px 20px' }}
                            params={{
                                desktop: 'quality=100',
                                tablet: 'quality=100',
                                mobile: 'quality=100',
                            }}
                            dimensions={logoDimensions}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

ContainerBrandLogo.propTypes = {
    menuBlocks: array.isRequired,
    plaEnabled: bool.isRequired,
    keeperData: object,
    partnershipLogo: string,
};

ContainerBrandLogo.defaultProps = {
    keeperData: {},
    partnershipLogo: '',
};

export default ContainerBrandLogo;
